function SocialIcons() {
  const imageClass =
    'h-8 w-8 cursor-pointer fill-white hover:fill-secondary transition-all duration-300';

  return (
    <div className="flex flex-row items-start justify-start gap-[1.25rem]">
      <div
        className={imageClass}
        onClick={() =>
          window.open('https://www.instagram.com/binnarpolska/', '_blank')
        }
      >
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-inherit"
        >
          <g clipPath="url(#clip0_132_200)" className="fill-inherit">
            <path
              d="M18.4668 15.5C18.4668 17.1385 17.1385 18.4668 15.5 18.4668C13.8615 18.4668 12.5332 17.1385 12.5332 15.5C12.5332 13.8615 13.8615 12.5332 15.5 12.5332C17.1385 12.5332 18.4668 13.8615 18.4668 15.5Z"
              className="fill-inherit"
            />
            <path
              d="M22.4383 10.2508C22.2956 9.86439 22.0681 9.51459 21.7725 9.22746C21.4854 8.93182 21.1358 8.7043 20.7491 8.56168C20.4355 8.43988 19.9644 8.2949 19.0966 8.2554C18.1579 8.21259 17.8764 8.20337 15.5 8.20337C13.1233 8.20337 12.8418 8.21236 11.9033 8.25517C11.0356 8.2949 10.5642 8.43988 10.2508 8.56168C9.86414 8.7043 9.51434 8.93182 9.22746 9.22746C8.93182 9.51459 8.70429 9.86415 8.56144 10.2508C8.43964 10.5645 8.29465 11.0358 8.25516 11.9036C8.21235 12.8421 8.20312 13.1235 8.20312 15.5002C8.20312 17.8767 8.21235 18.1581 8.25516 19.0968C8.29465 19.9646 8.43964 20.4357 8.56144 20.7493C8.70429 21.136 8.93158 21.4856 9.22722 21.7727C9.51434 22.0684 9.86391 22.2959 10.2506 22.4385C10.5642 22.5605 11.0356 22.7055 11.9033 22.745C12.8418 22.7878 13.123 22.7968 15.4997 22.7968C17.8767 22.7968 18.1581 22.7878 19.0964 22.745C19.9641 22.7055 20.4355 22.5605 20.7491 22.4385C21.5253 22.1391 22.1388 21.5256 22.4383 20.7493C22.5601 20.4357 22.705 19.9646 22.7448 19.0968C22.7876 18.1581 22.7966 17.8767 22.7966 15.5002C22.7966 13.1235 22.7876 12.8421 22.7448 11.9036C22.7053 11.0358 22.5603 10.5645 22.4383 10.2508ZM15.5 20.0703C12.9757 20.0703 10.9294 18.0243 10.9294 15.5C10.9294 12.9757 12.9757 10.9296 15.5 10.9296C18.024 10.9296 20.0703 12.9757 20.0703 15.5C20.0703 18.0243 18.024 20.0703 15.5 20.0703ZM20.251 11.817C19.6611 11.817 19.1829 11.3388 19.1829 10.7489C19.1829 10.1591 19.6611 9.68085 20.251 9.68085C20.8409 9.68085 21.3191 10.1591 21.3191 10.7489C21.3189 11.3388 20.8409 11.817 20.251 11.817Z"
              className="fill-inherit"
            />
            <path
              d="M15.5 0C6.9409 0 0 6.9409 0 15.5C0 24.0591 6.9409 31 15.5 31C24.0591 31 31 24.0591 31 15.5C31 6.9409 24.0591 0 15.5 0ZM24.3467 19.1695C24.3037 20.1169 24.153 20.7638 23.933 21.33C23.4707 22.5256 22.5256 23.4707 21.33 23.933C20.764 24.153 20.1169 24.3034 19.1697 24.3467C18.2206 24.39 17.9174 24.4004 15.5002 24.4004C13.0829 24.4004 12.7799 24.39 11.8305 24.3467C10.8833 24.3034 10.2362 24.153 9.67023 23.933C9.07612 23.7095 8.53829 23.3593 8.09365 22.9063C7.64097 22.4619 7.2907 21.9239 7.06719 21.33C6.84724 20.764 6.69658 20.1169 6.65353 19.1697C6.60978 18.2204 6.59961 17.9171 6.59961 15.5C6.59961 13.0829 6.60978 12.7796 6.6533 11.8305C6.69634 10.8831 6.84676 10.2362 7.06672 9.67C7.29022 9.07612 7.64073 8.53806 8.09365 8.09365C8.53806 7.64073 9.07612 7.29046 9.67 7.06696C10.2362 6.847 10.8831 6.69658 11.8305 6.6533C12.7796 6.61002 13.0829 6.59961 15.5 6.59961C17.9171 6.59961 18.2204 6.61002 19.1695 6.65353C20.1169 6.69658 20.7638 6.847 21.33 7.06672C21.9239 7.29022 22.4619 7.64073 22.9066 8.09365C23.3593 8.53829 23.7098 9.07612 23.933 9.67C24.1532 10.2362 24.3037 10.8831 24.3469 11.8305C24.3902 12.7796 24.4004 13.0829 24.4004 15.5C24.4004 17.9171 24.3902 18.2204 24.3467 19.1695Z"
              className="fill-inherit"
            />
          </g>
          <defs>
            <clipPath
              id="clip0_132_200"
              className="fill-inherit stroke-inherit"
            >
              <rect
                width="31"
                height="31"
                className="fill-inherit stroke-inherit"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div
        className={imageClass}
        onClick={() =>
          window.open('https://www.facebook.com/binnarpolska/', '_blank')
        }
      >
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          className="fill-inherit"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31 15.5C31 6.93867 24.0613 0 15.5 0C6.93867 0 0 6.93867 0 15.5C0 24.0613 6.93867 31 15.5 31C15.5908 31 15.6816 31 15.7725 30.9939V18.933H12.4424V15.052H15.7725V12.1941C15.7725 8.88223 17.7947 7.07793 20.7494 7.07793C22.1662 7.07793 23.3832 7.18086 23.7344 7.2293V10.6926H21.7C20.0955 10.6926 19.7807 11.4555 19.7807 12.5756V15.0459H23.6254L23.1229 18.927H19.7807V30.4006C26.2592 28.5418 31 22.5779 31 15.5Z"
            className="fill-inherit"
          />
        </svg>
      </div>
    </div>
  );
}

export default SocialIcons;
