import Link from 'next/link';
import { FunctionComponent } from 'react';

import createStyleForBackgroundImage from '@/helper/createStyleForBackgroundImage';
import Divider from './Divider';
import Logo from './Logo';
import ContactForm from './form/ContactForm';
import ContactFormInfo from './form/ContactFormInfo';
import SocialIcons from './nav/SocialIcons';

const pages = [
  {
    title: 'Strona główna',
    route: '/',
  },
  {
    title: 'O firmie',
    route: '/about',
  },
  {
    title: 'Oferta',
    route: '/offer',
  },
  {
    title: 'Realizacje',
    route: '/realizations',
  },
  // {
  //   title: 'Baza wiedzy',
  //   route: '/blog',
  // },
  {
    title: 'RODO',
    route: '/rodo',
  },
  {
    title: 'Polityka prywatności',
    route: '/privacy',
  },
  {
    title: 'FAQ',
    route: '/faq',
  },
];

const Footer: FunctionComponent = () => {
  return (
    <footer
      className="flex flex-col justify-center items-center pt-4 relative"
      style={createStyleForBackgroundImage('/footer/footer-bg.webp')}
      id="footer"
    >
      <div className={`md:px-6 lg:px-12 xl:px-18 2xl:px-32 my-8 max-w-full`}>
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-start gap-12 backdrop-card px-2 md:px-4 lg:px-12 py-8">
          <ContactForm />
          <ContactFormInfo />
        </div>
      </div>
      <div className="w-full h-8 bg-gradient-to-t from-tertiary to-transparent backdrop-filter backdrop-blur-md" />
      <div className="w-full flex flex-col justify-center items-center gap-4 bg-tertiary py-8">
        <div className="w-[80%] flex flex-row items-center gap-8">
          <Logo />
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-evenly flex-wrap gap-4 lg:gap-8">
              {pages.map((item, index) => (
                <Link
                  key={index}
                  className="text-default hover:text-secondary cursor-pointer transition-all duration-300 no-underline"
                  href={item.route}
                >
                  {item.title}
                </Link>
              ))}
            </div>
            <Divider />
          </div>
        </div>
        <div className="w-[80%] flex flex-col gap-4 md:gap-0 md:flex-row justify-between items-center">
          <SocialIcons />
          <div className="text-[16px] font-normal text-[#555555]">
            Powered by Askay
          </div>
          <div className="text-subtle">
            Copyright © {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
