function Divider(props: { secondary?: boolean }) {
  return (
    <div className="self-stretch h-[0rem] flex flex-row items-start justify-start max-w-full">
      <div
        className={`"h-[0.06rem] w-full box-border max-w-full border-t-[1px] border-b-0 border-l-0 border-r-0 border-solid ${
          props.secondary ? 'border-secondary' : ' border-subtle'
        }`}
      />
    </div>
  );
}

export default Divider;
