import Link from 'next/link';
import { useState } from 'react';

import Logo from './Logo';
import BurgerIcon from './icon/BurgerIcon';

const navbarItems = [
  {
    title: 'Strona główna',
    route: '/',
  },
  {
    title: 'O firmie',
    route: '/about',
  },
  {
    title: 'Oferta',
    route: '/offer',
  },
  {
    title: 'Realizacje',
    route: '/realizations',
  },
  {
    title: 'Baza wiedzy',
    route: '/blog',
  },
  {
    title: 'Kontakt',
    route: '/contact',
  },
];

const navItemClass =
  'cursor-pointer text-default hover:text-secondary transition-all duration-300 no-underline';

function Navbar() {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);

  return (
    <nav className="flex flex-col justify-center items-center px-8 xl:px-18 2xl:px-32 py-4 bg-primary">
      <div className="hidden w-full xl:flex flex-row items-center justify-evenly">
        <div className="flex flex-row gap-12">
          {navbarItems.slice(0, 3).map((item, index) => (
            <Link key={index} className={navItemClass} href={item.route}>
              {item.title}
            </Link>
          ))}
        </div>
        <Logo />
        <div className="flex flex-row gap-12">
          {navbarItems.slice(3, 5).map((item, index) => (
            <Link key={index} className={navItemClass} href={item.route}>
              {item.title}
            </Link>
          ))}
          {navbarItems.slice(5).map((item, index) => (
            <div
              key={index}
              className={navItemClass}
              onClick={() => {
                document
                  .getElementById('footer')!
                  .scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div className="flex xl:hidden w-full flex-row justify-between items-center">
        <Logo />
        <BurgerIcon
          isToggled={isBurgerToggled}
          onClick={() => setIsBurgerToggled(!isBurgerToggled)}
        />
      </div>
      <div
        className={`${
          isBurgerToggled ? 'flex' : 'hidden'
        } bg-defaultDark flex-col justify-start items-start px-12 py-6 gap-4 w-full`}
      >
        {navbarItems.slice(0, 5).map((item, index) => (
          <Link
            key={index}
            className={navItemClass}
            href={item.route}
            onClick={() => setIsBurgerToggled(false)}
          >
            {item.title}
          </Link>
        ))}
        {navbarItems.slice(5).map((item, index) => (
          <div
            key={index}
            className={navItemClass}
            onClick={() => {
              document
                .getElementById('footer')!
                .scrollIntoView({ behavior: 'smooth' });
            }}
          >
            {item.title}
          </div>
        ))}
      </div>
    </nav>
  );
}

export default Navbar;
