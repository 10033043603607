function Button(props: {
  label: string;
  onClick?: () => void;
  secondary?: boolean;
  class?: string;
  disabled?: boolean;
}) {
  return (
    <button
      disabled={props.disabled}
      className={`${
        props.secondary
          ? props.disabled
            ? 'border-black bg-black text-default'
            : 'border-transparent bg-[#F9B443] text-defaultDark hover:bg-defaultDark hover:text-[#F9B443]'
          : props.disabled
            ? 'border-primary bg-primary text-default'
            : 'border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-default'
      } ${
        props.disabled ? 'opacity-50 i cursor-not-allowed' : 'cursor-pointer'
      } border border-solid py-2 px-8 flex flex-row items-center justify-center whitespace-nowrap w-[15.56rem] transition-all duration-300 ${
        props.class
      }`}
      onClick={props.onClick}
    >
      <div className="font-bold text-[16px] leading-[23.7px]">
        {props.label}
      </div>
    </button>
  );
}

export default Button;
