function CustomCheckbox(props: {
  label: string;
  onChange: (checked: boolean) => void;
  value: boolean;
  labelClass?: string;
  class?: string;
}) {
  return (
    <div
      className={`flex flex-row items-start justify-start gap-[0.63rem] ${props.class}`}
    >
      <input
        className="h-[0.94rem] w-[0.94rem] rounded-checkbox"
        type="checkbox"
        onChange={(e) => props.onChange(e.currentTarget.checked)}
        checked={props.value}
      />
      <div className={props.labelClass}>{props.label}</div>
    </div>
  );
}

export default CustomCheckbox;
