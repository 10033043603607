import Divider from '../Divider';

export function ContactBlock(props: {
  children: JSX.Element;
  imageUri: string;
}) {
  return (
    <div className="flex flex-row items-start justify-start gap-[0.5rem]">
      <img
        className="h-[1.31rem] w-[1.31rem]"
        alt="contact image"
        src={props.imageUri}
        loading="lazy"
      />
      {props.children}
    </div>
  );
}

function ContactFormInfo() {
  const contactBlockClass =
    'cursor-pointer text-default hover:text-secondary transition-all duration-300';

  return (
    <div className="lg:w-[50%] flex flex-col items-start justify-start gap-4">
      <div className="text-default">
        Skorzystaj z formularza, aby nawiązać z nami kontakt. Czekamy na Twoje
        pytania, wątpliwości oraz zapytania ofertowe. Nasi specjaliści są
        gotowi, by zapewnić Ci wsparcie oraz niezbędne informacje dotyczące
        naszych produktów i usług. Odpowiemy na Twoje zapytanie szybko,
        dostarczając odpowiedzi dostosowane do Twoich potrzeb.
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <ContactBlock imageUri="/icon/phone-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() => (window.location.href = 'tel:+48608628309')}
          >
            Ogólny: +48 608 628 309
          </div>
        </ContactBlock>
        <ContactBlock imageUri="/icon/mail-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() =>
              (window.location.href = 'mailto:biuro@binnar.com.pl')
            }
          >
            biuro@binnar.com.pl
          </div>
        </ContactBlock>
        <ContactBlock imageUri="/icon/phone-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() => (window.location.href = 'tel:+48533809470')}
          >
            Dział sprzedaży: +48 533 809 470
          </div>
        </ContactBlock>
        <ContactBlock imageUri="/icon/mail-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() =>
              (window.location.href = 'mailto:biuro@binnar.com.pl')
            }
          >
            biuro@binnar.com.pl
          </div>
        </ContactBlock>
        <ContactBlock imageUri="/icon/phone-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() => (window.location.href = 'tel:+48787946374')}
          >
            Dział techniczny: +48 787 946 374
          </div>
        </ContactBlock>
        <ContactBlock imageUri="/icon/mail-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() =>
              (window.location.href = 'mailto:k.kocula@binnar.com.pl')
            }
          >
            k.kocula@binnar.com.pl
          </div>
        </ContactBlock>
        <ContactBlock imageUri="/icon/phone-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() => (window.location.href = 'tel:+48662129159')}
          >
            Dział realizacji: +48 662 129 159
          </div>
        </ContactBlock>
        <ContactBlock imageUri="/icon/mail-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() =>
              (window.location.href = 'mailto:m.piotrowski@binnar.com.pl')
            }
          >
            m.piotrowski@binnar.com.pl
          </div>
        </ContactBlock>
      </div>
      <Divider secondary />
      <div className="grid grid-cols-2 gap-4">
        <ContactBlock imageUri="/icon/pin-orange.svg">
          <div
            className={contactBlockClass}
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  '27 Lesiany,Stradunia,Poland',
                )}`,
                '_blank',
              )
            }
          >
            ul. Lesiany 27
            <br />
            47-341 Stradunia
          </div>
        </ContactBlock>
        <ContactBlock imageUri="/icon/info-orange.svg">
          <div className={contactBlockClass}>
            KRS 0000899373
            <br />
            NIP 1990126403
            <br />
            Regon 388888921
          </div>
        </ContactBlock>
      </div>
    </div>
  );
}

export default ContactFormInfo;
