// components/AnalyticsScripts.tsx

import React from 'react';

const AnalyticsScripts: React.FC = () => (
  <>
    {/* Clarity Script */}
    <script
      dangerouslySetInnerHTML={{
        __html: `
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "k4x6kirl84");
        `,
      }}
    ></script>

    {/* CookieYes Script */}
    <script
      type="text/javascript"
      src="https://cdn-cookieyes.com/client_data/b9ac4ecc0cfaae43df958417/script.js"
    ></script>
  </>
);

export default AnalyticsScripts;
