function CustomTextarea(props: {
  placeholder: string;
  label: string;
  onChange: (text: string) => void;
  value: string;
  class?: string;
}) {
  return (
    <div className={`w-full p-1 md:p-4 flex flex-col gap-4 ${props.class}`}>
      <div className="text-[12px] text-default">{props.label}</div>
      <textarea
        className="w-full text-[14px] bg-[transparent] text-default text-left border-solid border-t-0 border-l-0 border-r-0 border-b border-white outline-none"
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
    </div>
  );
}

export default CustomTextarea;
