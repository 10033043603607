function Logo() {
  return (
    <div className="cursor-pointer" onClick={() => window.location.assign('/')}>
      <img
        className="h-[46px] w-[140px]"
        alt="logo icon"
        src="/logo.webp"
        loading="lazy"
      />
    </div>
  );
}

export default Logo;
